import {createWorkerUrlOverride, isWorkerUrlOverrideExists} from './createLocalWorker'
window.messages = {}

module.exports = isDebug => async (url, callback) => {
    if (isDebug && isWorkerUrlOverrideExists()) {
        url = await createWorkerUrlOverride()
    }
    const worker = new Worker(url)
    if (isDebug) {
        console.log('created worker', url)
    }

    let listener = null
    let isTerminated = false
    let bufferedMessages = []

    const id = Object.keys(window.messages).length

    const thisWorkersMessages = window.messages[id] = []

    worker.addEventListener('message', message => {
        if (isDebug) {
            const copy = JSON.parse(JSON.stringify(message.data))
            thisWorkersMessages.push({direction: 'INCOMING', ...copy, timestamp: performance.now()})
        }

        if (listener) {
            listener(message)
            return
        }
        bufferedMessages.push(message)
    })

    const addEventListener = (type, handler) => {
        if (type !== 'message') {
            throw new Error('cannot add event listener to message type which is not message')
        }
        if (listener !== null) {
            throw new Error('cannot add event listener twice')
        }

        if (isDebug) {
            console.log('bufferedMessages count', bufferedMessages.length)
        }

        bufferedMessages.forEach(message => handler(message))
        bufferedMessages = null

        listener = handler
    }

    const terminate = () => {
        if (!isTerminated) {
            isTerminated = true
            worker.terminate()
        }
    }

    const postMessage = (message, transfer) => {
        // we do this because when this message is mutated on the worker empty arrays have a shared reference
        if (message.type === 'wix_code_worker_start') {
            const clone = JSON.parse(JSON.stringify(message))
            if (isDebug) {
                thisWorkersMessages.push({direction: 'OUTGOING', ...clone, arg1: transfer, timestamp: performance.now()})
            }
            worker.postMessage(clone, transfer)
        } else {
            if (isDebug) {
                const clone = JSON.parse(JSON.stringify(message))
                thisWorkersMessages.push({direction: 'OUTGOING', ...clone, arg1: transfer, timestamp: performance.now()})
            }
            worker.postMessage(message, transfer)
        }
    }

    callback({
        postMessage,
        addEventListener,
        terminate
    })
}

